import { FC, useState } from "react"
import React from "reactn"
import { graphql } from "gatsby"
import marked from "marked"
import Meta, { MetaProps } from "../components/Meta"
import PageHeader from "../components/PageHeader"
import VimeoPlayer from "../components/VimeoPlayer"
import Link from "../components/Link"
import truncate from "../utils/truncate"
import Dialog from "../components/Dialog"

interface Position {
  title: string,
  content: string,
  href: string
}

interface KarrierePageTemplateProps {
  video: {
    title: string,
    vimeoId: string
  },

  info: {
    title: string,
    text: string,
    seAlleButton: string,
    applyHereButton: string
  },

  positions: {
    title: string,
    positions: Position[]
  }
}

const KarrierePageTemplate: FC<KarrierePageTemplateProps> = ({
  video,
  info,
  positions
}) => {
  const [ position, setPosition ] = useState<Position>()

  return (
    <main id="karriere-page">
      <VimeoPlayer
        id="karriere-video"
        title={video.title}
        vimeoId={video.vimeoId}
        playsinline={true}
        autoplay={true}
        muted={true}
        background={true}
        loop={true}
      />

      <section
        id="info"
        className="px-6 py-16"
      >
        <div className="mx-auto max-w-4xl text-center lg:max-w-2xl">
          <h1 className="title">
            {info.title}
          </h1>

          <hr className="sep" />

          <div
            className="markdown max-w-2xl mx-auto mt-12"
            dangerouslySetInnerHTML={{
              __html: marked(info.text)
            }}
          />
        </div>
      </section>

      <h2 className="section-label">
        {positions.title}
      </h2>

      <div className="px-6">
        <hr className="sep" />
      </div>

      <section
        id="ledige-stillinger"
        className="px-6 pb-16 lg:px-3 lg:pb-6"
      >
        <div className="flex mx-auto max-w-6xl flex-wrap">
          {positions.positions.map((position, index) => (
            <button
              key={index}
              className="text-left w-1/2 p-3 hover:no-underline lg:w-full"
              onClick={() => { setPosition(position) }}
            >
              <div className="text-black p-6 rounded shadow bg-gray-100 cursor-pointer hover:bg-gray-300">
                <h3 className="title text-xl font-semibold lg:text-lg">
                  {position.title}
                </h3>

                <div
                  className="mt-4 markdown"
                  dangerouslySetInnerHTML={{
                    __html: marked(truncate(position.content, 150))
                  }}
                />
              </div>
            </button>
          ))}
        </div>
      </section>

      <PositionDialog
        shown={!!position}
        position={position}
        onClose={() => { setPosition(undefined) }}
      />
    </main>
  )
}

interface PositionDialogProps {
  onClose: () => void,
  shown: boolean,
  position?: Position
}

const PositionDialog: FC<PositionDialogProps> = ({
  shown,
  onClose,
  position
}) => position ? (
  <Dialog
    shown={shown}
    title={position.title}
    onClose={onClose}
    width={700}
  >
    <div
      className="p-6 markdown"
      dangerouslySetInnerHTML={{
        __html: marked(position.content)
      }}
    />

    <div className="border-t border-solid border-gray-900 p-6 flex justify-center">
      <Link
        className="btn-primary px-12"
        to={position.href}
      >
        Søk her
      </Link>
    </div>
  </Dialog>
) : null

interface KarrierePageProps {
  data: {
    markdownRemark: {
      frontmatter: KarrierePageTemplateProps & {
        meta: MetaProps
      }
    }
  }
}

const KarrierePage: React.FC<KarrierePageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <Meta {...frontmatter.meta} />

      <PageHeader />

      <KarrierePageTemplate
        video={frontmatter.video}
        info={frontmatter.info}
        positions={frontmatter.positions}
      />
    </>
  )
}

export default KarrierePage

export const KarrierePageQuery = graphql`
  query KarrierePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        meta {
          title
          description
          css
          js
        }

        video {
          title
          vimeoId
        }

        info {
          title
          text
        }

        positions {
          title
          positions {
            title
            content
            href
          }
        }

      }
    }
  }
`
